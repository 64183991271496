import React from "react";
import { cx } from "@/src/utils/all";
import { MinusCircle, PlusCircle } from "lucide-react";
import { isNumber } from "lodash";

interface QuantityInputProps {
  value?: number;
  max?: number;
  onChange?: (value: number) => void;
  containerClass?: string;
  layout?: "horizontal" | "vertical";
}

const QuantityInput: React.FC<QuantityInputProps> = ({
  value,
  max,
  onChange,
  containerClass,
  layout,
}) => {
  const updateQuantity = (num: any) => {
    let newValue = (value ?? 0) + num;
    if (max && newValue > max) {
      return;
    }
    newValue = newValue < 0 ? 0 : newValue;
    onChange?.(newValue);
  };

  // Change from input
  const onChangeQuantity = (val: string) => {
    const num = parseInt(val);
    if (isNaN(num) || !isNumber(num)) {
      onChange?.(0);
      return;
    }

    if (max && num > max) {
      return;
    }
    const newValue = num < 0 ? 0 : num;
    onChange?.(newValue);
  };

  return (
    <div
      className={cx(
        "flex items-center gap-1",
        containerClass,
        layout === "vertical" ? "flex-col" : "",
      )}
    >
      <MinusCircle
        onClick={() => updateQuantity(-1)}
        size={20}
        strokeWidth={1.5}
        className={
          value && value <= 0
            ? "text-gray-300"
            : "cursor-pointer text-slate-500"
        }
      />
      <input
        className="mb-0 w-[40px] border-none py-0 text-center focus:ring-0"
        value={value ?? ""}
        onChange={(e) => onChangeQuantity(e.target.value)}
      />
      <PlusCircle
        onClick={() => updateQuantity(1)}
        size={20}
        strokeWidth={1.5}
        className={
          value && max && value >= max
            ? "text-gray-300"
            : "cursor-pointer text-slate-500"
        }
      />
    </div>
  );
};

export default QuantityInput;
