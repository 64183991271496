import { format } from "@/src/lib/dayjs";
import {
  dateFormat,
  dateTimeFormat,
  dateTimeSecondFormat,
  monthFormat,
} from "./constants";

export function getLocalLocale() {
  // ts trick to avoid type checking
  const _navigator: any = navigator;
  return (
    _navigator.userLanguage ||
    (navigator.languages &&
      navigator.languages.length &&
      navigator.languages[0]) ||
    navigator.language ||
    _navigator.browserLanguage ||
    _navigator.systemLanguage ||
    "en"
  );
}

export function isObjectUndefinedOrNull(obj: any) {
  return obj === undefined || obj === null;
}

export function isNullOrEmpty(text: string | null | undefined) {
  if (!text) {
    return true;
  }

  text = text.trim();
  return text.length < 1;
}

export function formatDate(value: string | Date | null | undefined) {
  if (!value) {
    return "";
  }
  return format(new Date(value), dateFormat);
}
export function formatMonth(value: string) {
  return format(new Date(value), monthFormat);
}
export function formatDateTime(value: string) {
  return format(new Date(value), dateTimeFormat);
}

export function formatDateTimeSecond(value: string) {
  return format(new Date(value), dateTimeSecondFormat);
}

export function formatCurrency(
  val: string | number,
  locale?: string,
  currency = "VND",
) {
  const convertedNum = Number(val);
  if (isNaN(convertedNum)) return val;

  const _locale = locale || "vi";

  return new Intl.NumberFormat(_locale, {
    style: "currency",
    currency,
    currencyDisplay: "narrowSymbol",
  }).format(convertedNum);
}

export function formatNumber(
  val: string | number | undefined | null,
  locale = "vi",
  currency = "vnd",
) {
  const convertedNum = Number(val);
  if (isNaN(convertedNum) || isObjectUndefinedOrNull(val)) return "";

  const _locale = locale || "vi";

  return new Intl.NumberFormat(_locale).format(convertedNum);
}

export function getColorByStatus(status: string) {
  switch (status) {
    case "active":
      return "bg-green-100 text-green-600 hover:bg-green-200 dark:bg-green-900 dark:text-green-400 dark:hover:bg-green-800";
    case "inactive":
      return "bg-red-100 text-red-600 hover:bg-red-200 dark:bg-red-900 dark:text-red-400 dark:hover:bg-red-800";
    case "inprogress":
      return "bg-blue-100 text-blue-600 hover:bg-blue-200 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800";
    default:
      return "bg-gray-100 text-gray-600  hover:bg-gray-200 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-800";
  }
}

export const variants = (x = 1000, opacity = 0) => ({
  enter: (direction: number) => {
    return {
      x: direction > 0 ? x : -x,
      opacity,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? x : -x,
      opacity,
    };
  },
});

export const range = (start: number, end: number) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += 1) {
    output.push(i);
  }
  return output;
};

function forceDownload(blobUrl: string, filename: string) {
  let a: any = document.createElement("a");
  a.download = filename;
  a.href = blobUrl;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function stringToSlug(str: string, option = 0) {
  let from, to;
  // remove all marks (Latin alphabet)
  if (option === 0) {
    from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ";
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  }
  //remove tone mark (Vietnamese alphabet)
  else {
    from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ";
    to =
      "aaaaaăăăăăăââââââeeeeeêêêêêêđuuuuuưưưưưưoooooôôôôôôơơơơơơiiiiiaeiiouuncyyyyy";
  }

  str = str.toLocaleLowerCase();

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replaceAll(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\-]/g, "-")
    .replace(/-+/g, "-");

  return str;
}

export default function downloadPhoto(url: string, filename: string) {
  if (!filename) {
    filename = url.split("\\").pop()?.split("/").pop() || "";
  }
  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch((e) => console.error(e));
}

export function compressImage(file: any, maxSize: number) {
  const image = new Image();
  const canvas = document.createElement("canvas");
  const dataURItoBlob = function (dataURI: string) {
    const bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };
  const reader = new FileReader();
  const resize = function () {
    let width = image.width;
    let height = image.height;
    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL("image/jpeg");
    return dataURItoBlob(dataUrl);
  };
  return new Promise(function (ok, no) {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }
    reader.onload = function (readerEvent) {
      image.onload = function () {
        return ok(resize());
      };
      image.src = readerEvent.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
}

export const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function filterOptions(input: string, option: any) {
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export function parsePagedParams(params: any) {
  let { page, limit } = params || {};

  if (page === undefined || page === null) {
    page = 1;
  }
  if (limit === undefined || limit === null) {
    limit = 12;
  }

  return {
    skip: (Number(page) - 1) * Number(limit), // How many rows to skip
    take: Number(limit),
  };
}
