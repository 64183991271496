import { cn } from "@/lib/utils";
import React from "react";

interface IProps {
  children?: any;
  width?: string;
  height?: string;
  showShadow?: boolean;
  onClick?: () => void;
}

export default function IconWrapper({
  children,
  width = "w-10",
  height = "h-10",
  showShadow = false,
  onClick,
}: IProps) {
  return (
    <div
      className={cn(
        `icon-cart flex ${width} ${height} items-center justify-center rounded-full bg-white`,
        showShadow ? "shadow-md" : "",
        onClick ? "cursor-pointer" : "",
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
