"use client";

import { History, Home, ShoppingBag, UserIcon } from "lucide-react";
import React from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useAuthStore, useOrderStore } from "@/src/stores/rootStoreProvider";
import { useTranslation } from "@/app/i18n/client";

export default function BottomNavigationBar({ user }: any) {
  const pathname = usePathname();
  const authStore = useAuthStore();
  const orderStore = useOrderStore();
  const { t } = useTranslation("common");

  const navItems = [
    { icon: Home, title: t("header.home"), path: "/" },
    { icon: ShoppingBag, title: t("header.cart"), path: "/cart" },
    { icon: History, title: t("header.orders"), path: "/orders" },
    { icon: UserIcon, title: t("header.profile"), path: "/profile" },
  ];

  const buildNavigationItem = (
    key: any,
    item: any,
    isActive: boolean,
    onClick?: any,
  ) => {
    return (
      <div
        key={key}
        className={`navigation-item flex cursor-pointer flex-col items-center ${
          isActive ? "font-semibold" : "text-slate-400"
        }`}
        onClick={onClick}
      >
        <div className="relative">
          <item.icon />
          {isActive && (
            <div className="absolute -inset-1 -z-10 rounded-full bg-lime-50 blur-sm"></div>
          )}
        </div>
        <span className="title mt-1 text-xs">{item.title}</span>
      </div>
    );
  };

  const openProfile = () => {
    if (user) {
      authStore.setShowProfile(true);
    } else {
      authStore.setShowSignIn(true);
    }
  };

  const openCart = () => {
    orderStore.showCartBottomSheet();
  };

  return (
    <>
      <section
        className="navigation-bar sticky bottom-0 left-0 right-0 mb-0 mt-4 rounded-t-xl bg-white"
        style={{ boxShadow: "0 0 9px rgba(147, 96, 3, 0.2)" }}
      >
        <div className="items flex justify-around py-2">
          {navItems.map((item, index) => {
            const isActive = pathname === item.path;
            if (item.path.toLowerCase() === "/profile") {
              return buildNavigationItem(index, item, isActive, openProfile);
            }
            if (item.path.toLowerCase() === "/cart") {
              return buildNavigationItem(index, item, isActive, openCart);
            }
            return (
              <Link href={item.path} key={index}>
                {buildNavigationItem(index, item, isActive)}
              </Link>
            );
          })}
        </div>
      </section>
    </>
  );
}
