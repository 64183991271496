"use client";
import { createContext } from "react";

export const SiteContext = createContext<any>(undefined);

export function SiteProvider({ children, site }: any) {
  return (
    <SiteContext.Provider value={{ site }}>{children}</SiteContext.Provider>
  );
}
