import { cn } from "@/lib/utils";
import BlurImage from "../../blur-image";

export default function CarouselItem({
  imageUrl,
  title,
  active,
  style,
  onClick,
}: any) {
  return (
    <div
      className={cn(
        "carousel-item flex items-center justify-center gap-4",
        active ? "active" : "",
      )}
      style={style}
      onClick={onClick}
    >
      <div className="relative h-[100px] w-[100px]">
        <BlurImage src={imageUrl} alt={title} className="rounded-full border" />
      </div>
      <div className="truncate text-ellipsis font-semibold">{title}</div>
    </div>
  );
}
