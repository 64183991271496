"use client";
import { useTranslation } from "@/app/i18n/client";
import FormInput from "@components/form-items/FormInput";
import { PAYMENT_METHOD, paymentMethodOptions } from "@/src/lib/constants";
import { useOrderStore } from "@stores/rootStoreProvider";
import { Button, Checkbox, Drawer, Form, Input } from "antd";
import { Suspense, useEffect, useState } from "react";
import { toast } from "sonner";
import PageLoadingComponent from "@/app/[domain]/loading";
import { addOrder } from "@/src/actions/finance/order";
import { X } from "lucide-react";
import { validateMessages } from "@/src/lib/validation";
import EmptyComponent from "@/src/components/app-items/empty.component";
import { formatCurrency } from "@/src/lib/helper";
import ConfettiExplosion from "react-confetti-explosion";
import FormTextArea from "@/src/components/form-items/FormTextArea";
import BlurImage from "@/src/components/blur-image";
import { observer } from "mobx-react";
import FormQuantityInput from "@/src/components/form-items/FormQuantity";
import FormSelect from "@/src/components/form-items/FormSelect";

const rules = {
  siteId: [{ required: true }],
  userId: [{ required: true }],
  orderType: [{ required: true }],
  paymentMethod: [{ required: true }],
  contactName: [{ required: true }],
  contactPhone: [{ required: true }],
  contactEmail: [{ type: "email" as const }],
  deliveryAddress: [{ required: true }],
} as any;

const OrderFormPage = observer(({ user, site }: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  const orderStore = useOrderStore();
  const [isLoading, setIsLoading] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [createOrderSuccess, setCreateOrderSuccess] = useState(false);
  const paymentMethods = paymentMethodOptions.filter((item) => item.enable);

  const [order, setOrder] = useState<any>({});

  useEffect(() => {
    setOrder(orderStore.editOrder);
    form.setFieldsValue(orderStore.editOrder);
    updateTotalAmount();
  }, [orderStore.editOrder]);

  useEffect(() => {
    if (!orderStore.showDrawer) {
      setCreateOrderSuccess(false);
    }
  }, [orderStore.showDrawer]);

  useEffect(() => {
    const init = async () => {
      const order = {
        ...orderStore.editOrder,
        siteId: site?.id,
        userId: user?.id,
        contactName: user?.name,
        contactEmail: user?.email,
        contactPhone: user?.phone,
        deliveryAddress: user?.address,
        paymentMethod: PAYMENT_METHOD.cash,
      };
      setOrder(order);
      orderStore.initOrder(order);
      form.setFieldsValue(order);
    };

    init();
  }, []);

  const getCartItem = (productId: string) => {
    if (!productId) {
      return;
    }
    return orderStore.editOrder?.orderDetails?.find(
      (item) => item.productId === productId,
    );
  };

  const onSave = async () => {
    form.validateFields().then(async (values) => {
      setIsLoading(true);
      const newOrder = {
        ...orderStore.editOrder,
        ...values,
        siteId: site?.id,
        userId: user?.id,
      };
      newOrder.orderDetails.forEach((item: any) => {
        delete item.product;
      });

      const res = await addOrder(newOrder);
      if (res.error) {
        setIsLoading(false);
        toast.error(t(res.error));
        return;
      }

      setIsLoading(false);
      form.resetFields();
      setCreateOrderSuccess(true);
      orderStore.initOrder({ ...newOrder, orderDetails: [] });
    });
  };

  const updateTotalAmount = () => {
    // Calculate total amount by order details
    const orderDetails = form.getFieldValue("orderDetails") ?? [];
    const totalAmount = orderDetails.reduce(
      (acc: number, item: any) =>
        acc + Math.abs(item.unitPrice ?? 0) * Math.abs(item.quantity),
      0,
    );
    form.setFieldsValue({ totalAmount });
    setOrder({ ...order, orderDetails, totalAmount });
  };

  const updateSelectedPaymentMethod = (val: any) => {
    setOrder({ ...order, paymentMethod: val });
  };

  const orderCartComponent = () => {
    return (
      <div className="relative flex w-full min-w-0 flex-col break-words rounded-lg bg-white">
        <div>
          {/* PAYMENT */}
          <Form.List name="orderDetails">
            {(fields, { remove }) => (
              <>
                <div className="border-blueGray-200 flex flex-col gap-4">
                  {fields.map((field, index) => {
                    const selectedProductId = form.getFieldValue([
                      "orderDetails",
                      field.name,
                      "productId",
                    ]);
                    const quantity = form.getFieldValue([
                      "orderDetails",
                      field.name,
                      "quantity",
                    ]);
                    const cartItem = getCartItem(selectedProductId) ?? {};
                    const selectedProduct = cartItem.product;
                    if (!selectedProduct) {
                      return <></>;
                    }
                    const ruleQuantity =
                      rules[`orderDetails.${field.name}.quantity`];
                    const showNoteColumn =
                      cartItem.note || cartItem.variant || cartItem.spiciness;

                    return (
                      <div className="flex flex-col gap-4" key={index}>
                        <div>
                          <div className="flex flex-row gap-2">
                            <div className="flex flex-1 flex-wrap gap-2">
                              <div className="flex-none">
                                <div className="relative h-20 w-20 md:h-[150px] md:w-[150px]">
                                  <BlurImage
                                    src={
                                      selectedProduct.image ||
                                      "/empty-state.png"
                                    }
                                    className="rounded-lg"
                                    alt={selectedProduct.name}
                                  />
                                </div>
                              </div>
                              <div className="flex flex-1 flex-col gap-2 md:flex-row md:items-center">
                                <div className="flex flex-1 flex-col gap-2 md:flex-row">
                                  <div className="flex flex-none flex-col flex-wrap gap-2">
                                    <div className="text-base font-semibold">
                                      {selectedProduct.name}
                                    </div>
                                    <div className="text-gray-500">
                                      <div>
                                        {t("order.unitPrice")}:{" "}
                                        <span className="text-gray-700">
                                          {formatCurrency(cartItem.unitPrice)} -{" "}
                                          {selectedProduct.unit}
                                        </span>
                                      </div>
                                      <div className="font-semibold">
                                        {t("order.tempAmount")}{" "}
                                        <span className="text-gray-700">
                                          {formatCurrency(
                                            cartItem.unitPrice * quantity,
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {showNoteColumn && (
                                    <div className="flex flex-auto flex-col flex-wrap gap-2">
                                      <div className="text-base font-semibold">
                                        {t("order.note")}
                                      </div>
                                      <div className="text-gray-500">
                                        {cartItem.note && (
                                          <div>{cartItem.note}</div>
                                        )}
                                        {cartItem.size && (
                                          <div>
                                            {t("order.productSize")}:{" "}
                                            {cartItem.size}
                                          </div>
                                        )}
                                        {cartItem.spiciness && (
                                          <div>
                                            {t("order.spiciness")}:{" "}
                                            {cartItem.spiciness}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mt-1 flex flex-none flex-col items-end justify-between">
                              <X
                                onClick={() => remove(field.name)}
                                className="ml-2"
                              />
                              <div className="hidden items-center justify-end gap-2 md:flex">
                                <FormQuantityInput
                                  name={[field.name, "quantity"]}
                                  rule={ruleQuantity}
                                  max={selectedProduct.remaining}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-end gap-2 md:hidden">
                            <FormQuantityInput
                              name={[field.name, "quantity"]}
                              rule={ruleQuantity}
                              max={selectedProduct.remaining}
                            />
                          </div>
                        </div>
                        {index < fields.length - 1 && (
                          <div className="h-[1px] border border-b border-dashed"></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Form.List>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      onClose={orderStore.showCartBottomSheet}
      open={orderStore.showDrawer}
      closable={false}
      placement="bottom"
      width="50%"
      height="80%"
      title={
        <h2 className="text-xl font-semibold dark:text-white">
          {t("order.orderTitle")}
        </h2>
      }
      extra={
        <div>
          <Button
            disabled={isLoading || order.orderDetails?.length <= 0}
            loading={isLoading}
            onClick={onSave}
            type="primary"
          >
            {t("btn.createOrder")}
          </Button>
        </div>
      }
      footer={
        <div className="flex justify-between">
          {/* SUMMARY */}
          <h2 className="font-semibold uppercase dark:text-white">
            {t("order.summaryGroup")}
          </h2>

          <div className="text-lg font-semibold">
            {formatCurrency(order.totalAmount)}
          </div>
        </div>
      }
    >
      <Form
        form={form}
        onFinish={onSave}
        autoComplete="off"
        layout="vertical"
        onFieldsChange={updateTotalAmount}
        validateMessages={validateMessages}
        className="custom-form"
      >
        <Suspense fallback={<PageLoadingComponent />}>
          {order.orderDetails?.length <= 0 && !createOrderSuccess && (
            <EmptyComponent
              imgUrl="/images/empty/empty-cart.png"
              imgWidth={200}
              imgHeight={200}
              title={t("order.emptyCartTitle")}
              description={t("order.emptyCartDescription")}
            />
          )}
          {createOrderSuccess && (
            <div className="relative">
              <ConfettiExplosion zIndex={2000} />
              <EmptyComponent
                imgUrl="/images/empty/success.png"
                imgWidth={120}
                imgHeight={120}
                title={t("order.createOrderSuccessTitle")}
                description={t("order.createOrderSuccessDescription")}
              />
            </div>
          )}
          {order.orderDetails?.length > 0 && (
            <>
              <div className="flex flex-col gap-4">
                {orderCartComponent()}
                {/* Contact info */}
                <div className="relative flex w-full min-w-0 flex-col break-words">
                  <h2 className="font-semibold uppercase dark:text-white">
                    {t("order.contactInfo")}
                  </h2>
                  <div className="border-blueGray-200 mt-5 border-t py-5">
                    <div className="flex flex-wrap gap-4">
                      <div className="w-full md:w-1/3">
                        <FormInput
                          label={t("user.name")}
                          name="contactName"
                          rule={rules.contactName}
                          formClass="mb-0"
                        ></FormInput>
                      </div>
                      <div className="flex-1 md:w-1/3">
                        <FormInput
                          label={t("user.email")}
                          name="contactEmail"
                          rule={rules.contactEmail}
                          formClass="mb-0"
                        ></FormInput>
                      </div>
                      <div className="flex-1 md:w-1/3">
                        <FormInput
                          label={t("user.phone")}
                          name="contactPhone"
                          rule={rules.contactPhone}
                          formClass="mb-0"
                        ></FormInput>
                      </div>
                      <div className="w-full">
                        <FormTextArea
                          label={t("order.address")}
                          name="deliveryAddress"
                          rule={rules.deliveryAddress}
                          formClass="mb-0"
                        ></FormTextArea>
                      </div>
                      {/* This function not ready yet */}
                      {false && (
                        <>
                          <div className="mb-2 w-full">
                            <Checkbox
                              checked={createAccount}
                              onChange={() => setCreateAccount(!createAccount)}
                            >
                              {t("order.doYouWantToCreateAccount")}
                            </Checkbox>
                          </div>
                          {createAccount && (
                            <>
                              <div className="md:w-1/3">
                                <FormInput
                                  label={t("order.password")}
                                  name="password"
                                  formClass="mb-0"
                                ></FormInput>
                              </div>
                              <div className="md:w-1/3">
                                <FormInput
                                  label={t("order.rePassword")}
                                  name="rePassword"
                                  formClass="mb-0"
                                ></FormInput>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* Payment method */}
                {user?.id && (
                  <div className="relative flex w-full min-w-0 flex-col break-words">
                    <h2 className="font-semibold uppercase dark:text-white">
                      {t("order.payment")}
                    </h2>
                    <div className="border-blueGray-200 mt-5 flex flex-wrap gap-4 border-t py-5">
                      <div className="w-full md:w-1/3">
                        <FormSelect
                          name="paymentMethod"
                          label={t("order.paymentMethod")}
                          options={paymentMethods}
                          rule={rules.paymentMethod}
                          onChange={updateSelectedPaymentMethod}
                        />
                      </div>
                      {/* Bank information */}
                      {order.paymentMethod === PAYMENT_METHOD.bank &&
                        site.bank && (
                          <div className="flex w-full gap-4">
                            <div className="w-full md:w-1/3">
                              <Input
                                disabled
                                size="large"
                                value={site?.bank}
                                placeholder={t("order.siteBank")}
                              />
                            </div>
                            <div className="w-full md:w-1/3">
                              <Input
                                disabled
                                size="large"
                                value={site?.bankBranch}
                                placeholder={t("order.siteBranch")}
                              />
                            </div>
                            <div className="w-full md:w-1/3">
                              <Input
                                disabled
                                size="large"
                                value={site?.bankAccountNumber}
                                placeholder={t("order.siteBankAccountNumber")}
                              />
                            </div>
                            {site?.bankQRCode && (
                              <div className="w-full">
                                <BlurImage
                                  src={site?.bankQRCode}
                                  width={250}
                                  height={250}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Suspense>
      </Form>
    </Drawer>
  );
});

export default OrderFormPage;
