import React from "react";
import { Form } from "antd";
import QuantityInput from "./QuantityInput";

interface FormQuantityInputProps {
  name: string | string[] | any[];
  value?: number;
  max?: number;
  onChange?: (value: number) => void;
  rule?: any;
  layout?: "horizontal" | "vertical";
}

const FormQuantityInput: React.FC<FormQuantityInputProps> = ({
  name,
  max,
  onChange,
  rule,
  layout = "horizontal",
}) => {
  return (
    <Form.Item name={name} noStyle rules={rule}>
      <QuantityInput max={max} onChange={onChange} layout={layout} />
    </Form.Item>
  );
};

export default FormQuantityInput;
