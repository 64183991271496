"use client";
import React from "react";
import { useOrderStore } from "@/src/stores/rootStoreProvider";
import { ShoppingCart } from "lucide-react";
import IconWrapper from "@/src/components/icons/icon-wrapper";
import { observer } from "mobx-react";

const CartIconComponent = observer(({ showShadow }: any) => {
  const orderStore = useOrderStore();

  const openCartBottomSheet = () => {
    orderStore.showCartBottomSheet();
  };

  return (
    <div className="relative">
      <IconWrapper onClick={openCartBottomSheet} showShadow={showShadow}>
        <ShoppingCart className="text-slate-600" />
        {orderStore.editOrder?.orderDetails?.length > 0 && (
          <div className="dot absolute right-1 top-2 h-2 w-2 rounded-full bg-red-500"></div>
        )}
      </IconWrapper>
    </div>
  );
});
export default CartIconComponent;
